exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-access-code-js": () => import("./../../../src/pages/Access-Code.js" /* webpackChunkName: "component---src-pages-access-code-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-login-js": () => import("./../../../src/pages/Login.js" /* webpackChunkName: "component---src-pages-login-js" */),
  "component---src-pages-logout-js": () => import("./../../../src/pages/Logout.js" /* webpackChunkName: "component---src-pages-logout-js" */),
  "component---src-pages-page-2-js": () => import("./../../../src/pages/page-2.js" /* webpackChunkName: "component---src-pages-page-2-js" */),
  "component---src-pages-password-reset-js": () => import("./../../../src/pages/Password-Reset.js" /* webpackChunkName: "component---src-pages-password-reset-js" */),
  "component---src-pages-patient-dashboard-js": () => import("./../../../src/pages/Patient-Dashboard.js" /* webpackChunkName: "component---src-pages-patient-dashboard-js" */),
  "component---src-pages-session-ended-js": () => import("./../../../src/pages/Session-Ended.js" /* webpackChunkName: "component---src-pages-session-ended-js" */),
  "component---src-pages-signup-js": () => import("./../../../src/pages/Signup.js" /* webpackChunkName: "component---src-pages-signup-js" */),
  "component---src-pages-team-js": () => import("./../../../src/pages/Team.js" /* webpackChunkName: "component---src-pages-team-js" */)
}

